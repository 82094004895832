import React from 'react'

const Files = ({ active }) => {
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
  return (
    <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0262 6.09377L13.0316 0.980398C12.4236 0.371211 11.571 0.00664886 10.5972 0.00664886H3.40932C1.58294 -0.115664 5.19278e-06 1.46727 5.19278e-06 3.29365V20.3402C-0.000777433 20.7882 0.0869121 21.232 0.258043 21.6461C0.429174 22.0602 0.680378 22.4364 0.997244 22.7531C1.31411 23.0699 1.6904 23.3209 2.10453 23.4919C2.51865 23.6629 2.96247 23.7504 3.4105 23.7495H15.5895C16.0375 23.7504 16.4813 23.6629 16.8955 23.4919C17.3096 23.3209 17.6859 23.0699 18.0028 22.7531C18.3196 22.4364 18.5708 22.0602 18.742 21.6461C18.9131 21.232 19.0008 20.7882 19 20.3402V8.40821C19 7.55558 18.6342 6.70296 18.0262 6.09377ZM5.84606 9.62539H9.5C9.98687 9.62539 10.4737 9.99114 10.4737 10.5991C10.4737 11.2083 10.1092 11.5729 9.5 11.5729H5.84606C5.7177 11.5746 5.59029 11.5507 5.47136 11.5023C5.35242 11.454 5.24438 11.3824 5.1536 11.2916C5.06283 11.2008 4.99117 11.0928 4.94286 10.9738C4.89454 10.8549 4.87056 10.7275 4.87231 10.5991C4.87231 9.98995 5.35919 9.62539 5.84606 9.62539ZM13.1539 16.444H5.84606C5.35919 16.444 4.87231 16.0783 4.87231 15.4703C4.87231 14.8623 5.23688 14.4965 5.84606 14.4965H13.1539C13.6408 14.4965 14.1277 14.8611 14.1277 15.4703C14.1277 16.0795 13.6408 16.444 13.1539 16.444Z"
        fill={`${active ? 'white' : 'black'}`} />
    </svg>

  )
}

export default Files