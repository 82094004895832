import React, { useState } from 'react';

export default function MyRadio({ data }) {

    let { question, options } = data;
    const [selectedValue, setSelectedValue] = useState('');

    const onChange = (value) => {
        setSelectedValue(value);
        console.log(value);
    }

    return (
        <div className='mt-6'>
            <p className='mb-2'>{question}</p>
            <div className='w-full flex flex-wrap gap-5'>
                {
                    options?.map((item, index) => (
                        <div
                            key={index}
                            className={`py-3 px-12 font-medium border rounded-md cursor-pointer duration-500 ${selectedValue === item ? 'text-primary border-primary bg-primaryLight' : 'text-Gray'}`}
                            onClick={() => onChange(item)}
                        >
                            {item}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}