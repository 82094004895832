import Chat from "../../../../assets/Chat";
import Dashboard from "../../../../assets/Dashboard";
import Document from "../../../../assets/Document";
import Files from "../../../../assets/Files";
import Settings from "../../../../assets/Settings";


export const sidebarData = [
    {
        title: 'Dashboard',
        icon: <Dashboard />,
        url: '/dashboard',
        activeUrl: ['/dashboard']
    },
    {
        title: 'Document',
        icon: <Document />,
        url: '/document',
        activeUrl: ['/document', '/documents_trash']
    },
    {
        title: 'Files',
        icon: <Files />,
        url: '/files',
        activeUrl: ['/files', '/create_files', '/question_answer_flow']
    },
    {
        title: 'Chat',
        icon: <Chat />,
        url: '/chat',
        activeUrl: ['/chat']
    },
    {
        title: 'Settings',
        icon: <Settings />,
        url: '/settings',
        activeUrl: ['/settings']
    },

];