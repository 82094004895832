// src/components/GoogleLogin.js
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleLoginComponent = () => {
    const handleSuccess = (response) => {
        console.log('Login Success:', response);
        // You can exchange the response token with your backend to get user details
    };

    const handleFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <GoogleOAuthProvider clientId="663041568169-3rtc27a6972pnqlqjentrmv78n580mj4.apps.googleusercontent.com" >
            <div className='me-4'>
                <GoogleLogin
                    allowed_parent_origin=""
                    onSuccess={handleSuccess}
                    onError={handleFailure}
                    theme='outline'
                    useOneTap
                >
                </GoogleLogin>
            </div> 
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginComponent;
