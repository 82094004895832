import { Avatar, Button, Timeline } from 'antd';
import React from 'react';
import QuestionContainer from '../../../components/questionContainer/QuestionContainer';


export default function QuestionAnswerFlow() {

    const stepsOfprocess = [
        { title: 'Taxpayer Information' },
        { title: 'Residential Address' },
        { title: 'General Information' },
        { title: 'Spouse Information' },
        { title: 'Source of Income & Uploads' },
        { title: 'Rental Income 1' },
        { title: 'Self Employed Income' },
        { title: 'Home Sale 1' },
        { title: 'Deductions' },
    ]
    
    const questions = [
        { id: '1', questionType: 'input', question: 'What do you want to name your comapny?', type: 'text', placeholder: 'Specific name' },
        { id: '2', questionType: 'input', type: 'text', placeholder: 'Random number assigned by the govt.' },
        { id: '3', questionType: 'input', type: 'text', question:'Address of the business', placeholder: 'Free entry answer' },
        // { id: '2', questionType: 'input', question: 'Income of last financial year', type: 'number', placeholder: 'Income' },
        { id: '5', questionType: 'date', question: 'Date you want the incorporation to be active' },
        { id: '6', questionType: 'radio', question: 'What product/services the business will provide?', options: ['Yes', 'No'] },
        // { id: '5', questionType: 'checkbox', question: 'Please confirm the calendar year you are completing this organizer for (Checkbox)', options: ['2023 (Jan 1 - Dec 31 2023)', '2022 (Jan 1 - Dec 31 2022)', "Other"] }
    ];

    return (
        <div>
            <div className='grid xl:grid-cols-4 gap-4'>
                <div className='border rounded-xl bg-white pt-3'>
                    <p className='text-primary text-center font-semibold border-b pb-3 '>2023 Individual Tax Organizer</p>
                    <div className='px-6 pt-8'>
                        <Timeline
                            className="custom-timeline"
                            items={stepsOfprocess?.map((item, index) => (
                                {
                                    dot: <Avatar shape='square' className={`cursor-pointer ${index < 4 ? 'border-primary border-2 font-semibold bg-transparent text-primary' : 
                                        'bg-white border-2 border-Gray font-semibold text-Gray'}`}>{index + 1}</Avatar>,
                                    children: <div className='ms-1 mb-2 hover:bg-gray-100 p-1 ps-2 rounded-md cursor-pointer'>
                                        <p className='text-secondaryLight1'>Step {index + 1}/{stepsOfprocess?.length}</p>
                                        <p className='font-bold'>{item?.title}</p>
                                    </div>,
                                }
                            ))}
                        />
                    </div>
                </div>
                <div className='xl:col-span-3'>
                    <div className='overflow-y-auto'>
                        <div className='flex flex-wrap justify-between items-center mt-4 bg-white py-3 px-4 rounded-lg'>
                            <div className='flex items-center gap-3'>
                                <div className='bg-secondaryLight h-8 w-8 rounded-full p-1 cursor-pointer'>
                                    <img src='/assets/icons/question-mark-circle.svg' alt='icon' />
                                </div>
                                <div className='bg-secondaryLight h-8 w-8 rounded-full p-1 cursor-pointer'>
                                    <img src='/assets/icons/ellipsis-vertical.svg' alt='icon' />
                                </div>
                                <div className='bg-secondaryLight h-8 w-8 rounded-full p-1 cursor-pointer'>
                                    <img src='/assets/icons/x-mark.svg' alt='icon' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5'>
                                <div className='flex items-center gap-2 cursor-pointer'>
                                    <img src='/assets/icons/compare.svg' alt='icon' />
                                    <p className='font-medium'>Compare Answers</p>
                                </div>
                                <div className='flex items-center gap-2 cursor-pointer'>
                                    <img src='/assets/icons/documents.svg' alt='icon' />
                                    <p className='font-medium'>Attached Docs</p>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white rounded-lg py-2 px-4 mt-3'>
                            <QuestionContainer questions={questions} />
                            <div className='question-container mt-8 w-4/5'>
                                <div className='mt-16 mb-6'>
                                    <div className='flex items-center gap-4'>
                                        <Button size='large' type='primary' className='py-6 px-12 uppercase font-semibold'>
                                            NEXT
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                            </svg>
                                        </Button>
                                        <Button size='large' type='primary' ghost className='py-6 px-12 uppercase font-semibold'>Submit</Button>
                                        <p className='text-secondaryLight1'>All answers are saved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
