import { DatePicker } from 'antd'
import React from 'react'

export default function MyDatePicker({ data }) {

    let { question } = data;

    const onChange = (date, dateString) => {
        console.log(dateString);
    }

    return (
        <div className='mt-6'>
            <p className='mb-2'>{question}</p>
            <DatePicker size='large' className='w-full py-3 ps-4' onChange={onChange} />
        </div>
    )
}
