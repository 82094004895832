import React from 'react'

const Document = ({ active }) => {
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
    return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8306 6.20696C21.7118 5.99258 21.5378 5.81398 21.3265 5.6898C21.1152 5.56562 20.8745 5.50042 20.6295 5.50098H7.59723C7.15786 5.50107 6.73036 5.64365 6.3789 5.90733C6.02744 6.17101 5.77097 6.54156 5.64799 6.96337L3.0684 15.8065C2.93262 16.272 2.64953 16.6809 2.26162 16.9719C1.87371 17.2628 1.4019 17.4201 0.916992 17.4202H17.3049C17.7899 17.4201 18.2618 17.2629 18.6498 16.9719C19.0378 16.681 19.3209 16.272 19.4568 15.8065L21.9332 7.31636C21.9908 7.13393 22.0114 6.94185 21.9938 6.75137C21.9763 6.56089 21.9205 6.37581 21.8306 6.20696Z" fill={`${active ? 'white' : 'black'}`} />
            <path d="M2.18671 15.5499L4.76767 6.70683C4.94748 6.09549 5.31997 5.55859 5.82965 5.17612C6.33934 4.79365 6.95895 4.58607 7.59618 4.5843H19.2541V4.12587C19.2541 3.63954 19.0609 3.17312 18.717 2.82923C18.3731 2.48534 17.9067 2.29215 17.4203 2.29215H10.432C10.2954 2.29218 10.1605 2.26174 10.0371 2.20306C9.91368 2.14438 9.80492 2.05892 9.71871 1.95291L8.81744 0.844886C8.60279 0.580953 8.33201 0.368176 8.02481 0.222026C7.7176 0.0758767 7.38168 2.95323e-05 7.04148 0H1.83372C1.34739 0 0.880973 0.193195 0.537084 0.537084C0.193195 0.880973 0 1.34739 0 1.83372L0 15.5866C0 15.8298 0.0965975 16.063 0.268542 16.2349C0.440487 16.4069 0.673693 16.5035 0.91686 16.5035C1.20304 16.5027 1.48129 16.4094 1.71014 16.2376C1.93898 16.0657 2.10615 15.8245 2.18671 15.5499Z" fill={`${active ? 'white' : 'black'}`}/>
        </svg>
    )
}

export default Document