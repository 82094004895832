import api from "../index";

export const CheckLoginUrlApi = (data) => api.post("checkloginurl", data);
export const RegisterApi = (data) => api.post("register", data);
export const LoginApi = (data) => api.post("login", data);
export const LoginOtpVeryficationApi = (data) => api.post("emailverification", data);
export const ForgotPasswordLinkApi = (data) => api.post("sendforgotlink", data);
export const ResetPasswordApi = (data) => api.post("resetpassword", data);
export const ResendOtpApi = (data) => api.post("resendotp", data);
export const LogoutApi = () => api.post('logout');
export const VerifyTFAuthenticationApi = (data) => api.post('verify2fa', data);