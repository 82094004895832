import api from "../../index";

export const VerifyPasswordApi = (data) => api.post('checkPassword', data);
export const GetAuthDetailsApi = () => api.post('enable2fa');
export const VerifyTFAuthenticationApi = (data) => api.post('verify2fa', data);
export const DisableTFAuthenticationApi = () => api.post('disable2fa');
export const ChangePasswordApi = (data) => api.post('changepassword', data);
export const GetSettingsDataApi = () => api.post('setting-data');
export const ViewProfileApi = () => api.post('view-profile');
export const UpdateProfileApi = (data, headers) => api.post('update-profile', data, headers);
export const NotificationSettingApi = (data) => api.post('notify-setting', data);