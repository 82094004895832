import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { RegisterApi } from '../../api/request/auth';
import toast from 'react-hot-toast';
import LoadableButton from '../../components/button/LoadableButton';
import { GetCountryDetailsApi } from '../../api/request/countryDetail'
import { CheckLoginUrlApi} from '../../api/request/auth';
import { setCookies } from '../../services/Cookies';
import GoogleLoginComponent from './components/GoogleLoginComponent';
import FacebookLoginComponent from './components/FacebookLoginComponent';


const { Option } = Select;

const Register = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({ first_name: '', last_name: '', mobile_no: '', email: '', password: '', tnc: false });
    const [isLoading, setIsLoading] = useState(false);
    const [countryDetails, setCountryDetails] = useState([]);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            delete values?.country_code;
            delete values?.tnc;
            const { data } = await RegisterApi(values);
            if (data?.status) {
                form.resetFields();
                toast.success(data?.message);
                navigate('/login');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    const getCountryDetails = async () => {
        try {
            const { data } = await GetCountryDetailsApi();
            if (data?.status) {
                setCountryDetails(data?.data);
            }
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
        }
    }
    const checkLoginUrl = async () => {
        try {
            const { data } = await CheckLoginUrlApi({ domain_name: "example.com" });
            if (data?.status) {
                setCookies('loginToken', data?.token);
            }
        }
        catch (error) {
        }
    }

    useEffect(() => {
        getCountryDetails();
        checkLoginUrl();
    }, [])


    return (
        <>
            <div className='lg:w-3/5 md:w-[80%] w-full sm:p-0 p-4'>
                <Form layout='vertical' autoComplete='off' onFinish={handleSubmit} from={form} initialValues={initialValues} className='space-y-5'>
                    <p className="sm:text-3xl text-2xl text-center font-bold">Welcome to <span className='text-primary'>Trackmydoc</span></p>
                    <div className='grid lg:grid-flow-col gap-4'>
                        <Form.Item
                            label={<p className='font-semibold'>First name</p>}
                            name="first_name"
                            rules={[{ required: true, message: 'Enter first name!', },]}
                        >
                            <Input placeholder='First name' size='large' />
                        </Form.Item>
                        <Form.Item
                            label={<p className='font-semibold'>Last name</p>}
                            name="last_name"
                            rules={[{ required: true, message: 'Enter last name!', },]}
                        >
                            <Input placeholder='Last name' size='large' />
                        </Form.Item>
                    </div>
                    <div className="flex sm:flex-row flex-col sm:gap-4">
                        <Form.Item name="country_code" className='sm:min-w-[28%]' rules={[{ required: true, message: 'Please select country', },]}>
                            <Select placeholder='Country code' size="large" >
                                {countryDetails.map((countryData, index) => (
                                    <Option key={index} value={countryData?.phonecode}>
                                        <div className="flex items-center space-x-3">
                                            <div className='rounded-full bg-secondaryLight w-9 h-9 flex justify-center items-center'>
                                                <img src={countryData?.image} alt="flag" className="w-7 h-7 object-contain rounded-full" />
                                            </div>
                                            <span className='text-base'>+ {countryData?.phonecode}</span>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="mobile_no" rules={[
                            { required: true, message: 'Please input mobile number!', },
                            // {
                            //     pattern: /^\d{10}$/,
                            //     message: 'Mobile number should be 10 digits!',
                            // },
                        ]} className='w-full'>
                            <Input size="large" placeholder="Mobile No." />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label={<p className='font-semibold'>Email Address</p>}
                        name="email"
                        style={{ marginTop: '0px' }}
                        rules={[
                            { required: true, message: 'Please input your email!', },
                            { type: 'email', message: 'Please input valid email!', },
                        ]}
                    >
                        <Input placeholder='Email address' size='large' />
                    </Form.Item>
                    <Form.Item
                        label={<p className='font-semibold'>Password</p>}
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password!', },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                message: 'Please enter strong password.',
                            },
                        ]}
                    >
                        <Input.Password placeholder='Password' size='large' />
                    </Form.Item>

                    <Form.Item name="tnc" valuePropName="checked" className='mb-0 mt-3'
                        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions.!')), }]}>
                        <Checkbox>
                            <div className='cursor-pointer'>
                                I agree all the
                                <span className='text-primary '> Terms </span>
                                and
                                <span className='text-primary '> Privacy Policies</span>
                            </div>
                        </Checkbox>
                    </Form.Item>
                    <LoadableButton
                        type='submit'
                        isLoading={isLoading}
                        lable='Create Account'
                        loadingLable='Creating...'
                        img='assets/icons/ArrowRight.svg'
                        className='bg-secondary text-white text-base w-full py-2 rounded-md mb-5 flex justify-center items-center'
                    />
                    <div className="flex items-center">
                        <hr className="flex-grow" />
                        <span className="mx-2 text-Gray">OR LOGIN WITH</span>
                        <hr className="flex-grow" />
                    </div>
                    <div className='flex lg:flex-row flex-col justify-center items-center lg:px-5 lg:space-y-0 space-y-2'>
                        <GoogleLoginComponent />
                        <FacebookLoginComponent/>
                        {/* <button type="button" className='border rounded-lg flex items-center justify-center py-2 w-full lg:me-3'>
                            <img src='assets/icons/google.svg' alt='icon' className='me-1' />
                            Sign in with Google
                        </button>
                        <button type="button" className='border rounded-lg flex items-center justify-center py-2 w-full'>
                            <img src='assets/icons/facebook.svg' alt='icon' className='me-1' />
                            Sign in with Facebook
                        </button> */}
                    </div>
                    <p className='text-center'>Already have an account ?  <Link to='/login' className='text-primary cursor-pointer'>Login</Link></p>
                </Form>
            </div>
        </>
    )
}

export default Register