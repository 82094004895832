import React, { useState } from "react";
import { Menu } from "antd";

const CreateFiles = () => {
    const [selectedService, setSelectedService] = useState("federal");
    const [current, setCurrent] = useState('incorporation');

    const serviceOptions = [
        { label: "Federal", value: "federal", icon:"federal.svg" },
        { label: "Ontario", value: "ontario", icon:"ontario.svg" },
        { label: "British Columbia", value: "british-columbia", icon:"british-columbia.svg" },
        { label: "Alberta", value: "alberta", icon:"alberta.svg" },
        { label: "Business Trade", value: "business-trade", icon:"business-trade.svg" },
    ];

    return (
        <div className="p-6 bg-white border rounded-xl">
            <h2 className="text-xl font-semibold">Available Services</h2>
            <div className="py-6">
                <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal">
                    <Menu.Item key="incorporation" className="text-base pb-3"> Incorporation Services </Menu.Item>
                    <Menu.Item key="canada" className="text-base pb-3" > Canada Services </Menu.Item>
                    <Menu.Item key="visa" className="text-base pb-3"> All Country Visa Service </Menu.Item>
                </Menu>
            </div>
            <div className="py-6">
                <h3 className="text-base font-semibold">Types of Incorporation</h3>
                <div className="flex justify-start items-center space-x-4 mt-6">
                    {serviceOptions.map((option, index) => (
                        <div key={index}
                            className={`cursor-pointer flex flex-col justify-center items-center border rounded-lg p-4 w-44 h-28 transition-all 
                                ${selectedService === option.value ? "bg-blue-50 border-primary text-primary"
                                    : "bg-White border-Gray text-Gray"
                                }`}
                            onClick={() => setSelectedService(option.value)}
                        >
                            <div className="flex items-center justify-center w-12 h-12 bg-blue-200 rounded-lg mb-2"> <img src={`/assets/icons/${option.icon}`} alt="icons" className="h-8 w-8 object-contain"/></div>
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex justify-start space-x-4 mt-6">
                <button className="flex items-center rounded-lg bg-secondary text-White py-2 px-4">
                    <span className="me-3">Next</span><img src="/assets/icons/arrow-right.svg" alt="icon" />
                </button>
                <button className="border border-secondary rounded-lg py-2 px-4 text-secondary">Submit</button>
            </div>
        </div>
    );
};

export default CreateFiles;
