import React from 'react';
const images = {
    Login: '/assets/images/login.png',
    SignUp: '/assets/images/signUp.png',
    Register: '/assets/images/register.png',
    ForgotPassword: '/assets/images/forgotPassword.png',
    ResetPassword: '/assets/images/resetPassword.png',
    Otp: '/assets/images/otp.png',
    Authentication: '/assets/images/authentication.png',
}
const AuthLayout = ({ children, title }) => {
    const imageSrc = images[title];
    return (
        <>
            <div className='min-h-screen overflow-hidden grid sm:grid-cols-2 grid-cols-1 sm:bg-[#F4F4F4]'>
                <div className='sm:ps-10 sm:py-5'>
                    <div className='h-full  bg-white rounded-lg pt-2'>
                        <div className='p-2'>
                            <img src='assets/icons/logo.svg' alt='icon'/>
                        </div>
                        <div className='h-full w-full flex justify-center items-center md:px-0 px-3'>{children}</div>
                    </div>
                </div>
                <div className='h-full grid justify-items-center content-center sm:mt-0 mt-2'>
                    <img src={imageSrc} alt="image not found" />
                </div>
            </div>
        </>
    )
}
export default AuthLayout