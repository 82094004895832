import React, { useState } from 'react';
import MySegmented from '../../components/segmented/Segmented';
import RadialBarChart from '../../components/chart/RadialBarChar';
import Add from '../../assets/Add';
import { useNavigate } from 'react-router-dom';

export default function Files() {

    const navigate = useNavigate();

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
    const [segmentedValue, setSegmentedValue] = useState('Active Files');

    return (
        <div>
            <div>
                <div className='border rounded-xl p-8 flex items-center justify-between bg-White'>
                    <div >
                        <img src="/assets/images/start-file-banner.png" alt="image" className=' object-contain' />
                    </div>
                    <div>
                        <p className='font-bold text-2xl'>Your project document with us</p>
                        <p className='text-secondaryLight1 mt-4'>Add project data, create thematic pages, edit data, share information with team members</p>
                        <button onClick={() => navigate('/create_files')} className='flex items-center justify-center gap-3 mt-4 bg-secondary text-white font-medium px-6 py-2 rounded-lg '>
                            <Add />
                            Start New File
                        </button>
                    </div>
                </div>
            </div>

            <div className='mt-8'>
                <MySegmented options={['Active Files', 'Archive Files']} value={segmentedValue} setValue={setSegmentedValue} />
                <div className='mt-8'>
                    <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-5'>
                        <div className='border rounded-xl bg-White'>
                            <div>
                                <div className='px-3'>
                                    <p className='font-semibold text-lg mt-4 mb-2'>Canada PR Service</p>
                                    <hr />
                                </div>
                                <div className='flex justify-between items-center px-4'>
                                    <div>
                                        <p className='font-medium mt-3'><span className='text-secondaryLight1'>Last Updated :</span> June-28</p>
                                        <div className='flex items-center gap-2 mt-3'>
                                            <div className='size-3 rounded-full bg-primary'></div>
                                            <p className='font-medium'>Seal</p>
                                        </div>
                                    </div>
                                    <div>
                                        <RadialBarChart values={[45]} labels={['Completed']} colors={[primaryColor]} value={true} height={150} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='border rounded-xl bg-White'>
                            <div>
                                <div className='px-3'>
                                    <p className='font-semibold text-lg mt-4 mb-2'>2023 Study visa for canada</p>
                                    <hr />
                                </div>
                                <div className='flex justify-between items-center px-4'>
                                    <div>
                                        <p className='font-medium mt-3'><span className='text-secondaryLight1'>Last Updated :</span> June-28</p>
                                        <div className='flex items-center gap-2 mt-3'>
                                            <div className='size-3 rounded-full bg-primary'></div>
                                            <p className='font-medium'>Seal</p>
                                        </div>
                                    </div>
                                    <div>
                                        <RadialBarChart values={[75]} labels={['Completed']} colors={[primaryColor]} value={true} height={150} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}