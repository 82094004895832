import React from 'react';

export default function Chat({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5V13C0.00145452 14.1522 0.40009 15.2687 1.12872 16.1613C1.85735 17.0538 2.87141 17.6679 4 17.9V21C3.99997 21.181 4.0491 21.3587 4.14213 21.514C4.23516 21.6693 4.36861 21.7965 4.52823 21.8819C4.68786 21.9673 4.86769 22.0078 5.04852 21.999C5.22935 21.9902 5.4044 21.9325 5.555 21.832L11.3 18H17C18.3256 17.9984 19.5964 17.4711 20.5338 16.5338C21.4711 15.5964 21.9984 14.3256 22 13V5C21.9984 3.67441 21.4711 2.40356 20.5338 1.46622C19.5964 0.528882 18.3256 0.00158786 17 0ZM15 12H7C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H15C15.2652 10 15.5196 10.1054 15.7071 10.2929C15.8946 10.4804 16 10.7348 16 11C16 11.2652 15.8946 11.5196 15.7071 11.7071C15.5196 11.8946 15.2652 12 15 12ZM17 8H5C4.73478 8 4.48043 7.89464 4.29289 7.70711C4.10536 7.51957 4 7.26522 4 7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H17C17.2652 6 17.5196 6.10536 17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7C18 7.26522 17.8946 7.51957 17.7071 7.70711C17.5196 7.89464 17.2652 8 17 8Z"  fill={`${active ? 'white' : 'black'}`} />
        </svg>

    )
}