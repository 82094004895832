import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import RadialBarChart from '../../components/chart/RadialBarChar';
import columns from '../../columns/dashboard/columns';
import SkeletonTable from '../../components/table/SkeletonTable';
import { GetClientDashboard } from '../../api/request/dashboard/dashboard';
import toast from 'react-hot-toast';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const dashboardCards = [
    { title: 'Tickets', total: '10', icon: '/assets/icons/ticket.svg', bgColor: 'bg-[#E5FAFF]' },
    { title: 'Active Files', total: '30', icon: '/assets/icons/active-file.svg', bgColor: 'bg-[#DDF6E8]' },
    { title: 'Apply Signature', total: '20', icon: '/assets/icons/apply-signature.svg', bgColor: 'bg-[#cdd8ec]' },
  ];
  const contactInfo = [
    { icon: '/assets/icons/phone.svg', label: 'Phone', value: dashboardData?.phone, },
    { icon: '/assets/icons/email.svg', label: 'Email', value: dashboardData?.email, },
    { icon: '/assets/icons/location.svg', label: 'Address', value: dashboardData?.address, },
    { icon: '/assets/icons/web.svg', label: 'Website', value: dashboardData?.website_url, },
  ];
  const socialLinks = [
    { name: 'Facebook', icon: '/assets/icons/facebook.svg', url: dashboardData?.facebook_url, bgColor: 'bg-[#3B599933]' },
    { name: 'Instagram', icon: '/assets/icons/instagram.svg', url: dashboardData?.instagram_url, bgColor: 'bg-gradient-to-b from-[rgba(219,0,172,0.2)] to-[rgba(255,203,0,0.2)]' },
    { name: 'LinkedIn', icon: '/assets/icons/linkedIn.svg', url: dashboardData?.linkedin_url, bgColor: 'bg-[#0B69C733]' },
  ];
  const chartData = {
    values: [60],
    labels: ['Active Organizers'],
    colors: ['#0BAFD4'],
  };
  const tableData = [
    { name: 'John Doe', status: 'Unpaid', amount: 150.0, },
    { name: 'Jane Smith', status: 'Unpaid', amount: 200.5, },
    { name: 'Michael Johnson', status: 'Unpaid', amount: 50.25, },
    { name: 'Emily Davis', status: 'Unpaid', amount: 120.75, },
  ]
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

  const getDashboardDetails = async () => {
    try {
      const { data } = await GetClientDashboard();
      if (data?.status) {
        setDashboardData(data?.data);
      }
    }
    catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  useEffect(() => {
    getDashboardDetails();
  }, []);

  useEffect(() => {
    setData(tableData.map((item) => {
      return {
        ...item,
        amount: (<p>$ {item.amount}</p>),
        action: (<button className='bg-primary rounded-lg text-White text-sm px-2 py-1 uppercase'>pay now</button>),
      }
    }))
  }, [])

  return (
    <>
      <div className='grid sm:grid-cols-4 gap-4 sm:px-0 px-1'>
        <div className='sm:col-span-3'>
          <div className='dashboard-banner bg-primary rounded-xl sm:h-56 flex justify-between items-center px-5 py-5'>
            <div className='bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center'>
              <img src="/assets/icons/left-angle.svg" alt="icon" className='object-contain ' />
            </div>
            <h4 className='text-White font-semibold text-2xl w-1/3'>
              Securely upload documents on our user-friendly trackmydoc platform
            </h4>
            <div className='bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center'>
              <img src="/assets/icons/right-angle.svg" alt="icon" className='object-contain ' />
            </div>
          </div>

          <div className='grid sm:grid-cols-3 gap-5 mt-5 '>
            {dashboardCards.map((item, index) =>
              <div key={index} className='border rounded-xl p-4 bg-white'>
                <div className='flex justify-between items-center'>
                  <div>
                    <p className='font-medium'>{item.title}</p>
                    <p className='font-semibold text-2xl mt-2'>{item.total}</p>
                  </div>
                  <div className={`${item.bgColor} rounded-xl size-14 flex items-center justify-center`}>
                    <img src={item.icon} alt='icon' />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='border rounded-xl p-5 mt-5 bg-White'>
            <div className='flex justify-between'>
              <p className='font-semibold'>File Status</p>
              <div className='flex gap-2'>
                <div className='bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center'>
                  <img src="/assets/icons/left-angle.svg" alt="icon" className='object-contain ' />
                </div>
                <div className='bg-secondaryLight h-6 w-6 rounded-full flex justify-center items-center'>
                  <img src="/assets/icons/right-angle.svg" alt="icon" className='object-contain ' />
                </div>
              </div>
            </div>
            <div className='mt-5 flex sm:flex-row flex-col gap-5'>
              <div className='border rounded-xl'>
                <div className='px-3'>
                  <h4 className='mt-3'>John - 2024 - 2458745468</h4>
                  <hr className='mt-1' />
                </div>
                <div className='flex sm:flex-row flex-col items-center justify-between px-5'>
                  <div className='space-y-4 py-2'>
                    <div className='text-Gray'>
                      <h4 className='mb-2'>24th Aug,2024</h4>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div>
                      <h4 className='font-bold mb-1'>Status</h4>
                      <p><span className='text-xl text-primary me-2'>●</span> Our Tax Expert Is Reviewing Your File</p>
                    </div>
                  </div>
                  <div className='flex justify-center items-center'>
                    {/* <RadialBarChart values={[75]} labels={['Completed']} colors={[primaryColor]} value={true} height={200} /> */}
                  </div>
                </div>
              </div>
              <div className='border rounded-xl'>
                <div className='px-3'>
                  <h4 className='mt-3'>John - 2024 - 2458745468</h4>
                  <hr className='mt-1' />
                </div>
                <div className='flex sm:flex-row flex-col items-center justify-between px-5'>
                  <div className='space-y-4 py-2'>
                    <div className='text-Gray'>
                      <h4 className='mb-2'>24th Aug,2024</h4>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div>
                      <h4 className='font-bold mb-1'>Status</h4>
                      <p><span className='text-xl text-primary me-2'>●</span>Pending</p>
                    </div>
                    <div>
                      <button className='bg-secondary text-White px-2 py-1 rounded-lg'>Complete</button>
                    </div>
                  </div>

                  <div className='flex justify-center items-center'>
                    {/* <RadialBarChart values={[45]} labels={['Completed']} colors={[primaryColor]} value={true} height={200} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='border rounded-2xl mt-5 p-6 bg-White'>
            <h3 className="text-xl font-bold mb-4">Waiting for action</h3>
            <div className="border border-primary rounded-md flex justify-between items-center p-4">
              <div>
                <h4 className="text-base font-medium">Respond to request</h4>
                <p className="text-sm text-secondaryLight2">Respond to request</p>
              </div>
              <button className="text-primary underline font-semibold text-base">
                Respond
              </button>
            </div>
          </div>

          <div className='border rounded-2xl my-5 bg-White'>
            <h1 className='my-5 mx-5 font-semibold text-xl'>Billing Details</h1>
            <div className='my-6 billing-table'>
              <SkeletonTable columns={columns} data={data} />
            </div>
          </div>
        </div>
        <div className='border rounded-2xl w-full space-y-5 bg-White'>
          <div className='px-4 py-4'>
            <div className='w-full border rounded-xl h-48 '></div>
          </div>
          <div className='bg-White'>
            <div className='bg-[#F1F1F1] py-2 ps-4'>Links</div>
            <div className='px-4 py-4 '>
              <div className='w-full lg:space-x-1 text-sm flex lg:flex-row flex-col lg:gap-0 gap-1'>
                <button className={`border rounded-md py-2 px-2 ${activeButton === 'uploadDocuments' ? 'border-black text-black' : 'border-Gray text-Gray'}`}
                  onClick={() => setActiveButton('uploadDocuments')}
                >
                  Upload documents
                </button>
                <button className={`border rounded-md py-2 px-2 ${activeButton === 'uploadFolder' ? 'border-black text-black' : 'border-Gray text-Gray'}`}
                  onClick={() => setActiveButton('uploadFolder')}
                >
                  Upload folder
                </button>
                <button className={`border rounded-md py-2 px-2 ${activeButton === 'newChat' ? 'border-black text-black' : 'border-Gray text-Gray'}`}
                  onClick={() => setActiveButton('newChat')}
                >
                  New chat
                </button>
              </div>
              <div className='w-full lg:space-x-1 mt-1 text-sm flex lg:flex-row flex-col lg:gap-0 gap-1'>
                <button className={`border rounded-md py-2 px-2 ${activeButton === 'bookAppointment' ? 'border-black text-black' : 'border-Gray text-Gray'}`}
                  onClick={() => setActiveButton('bookAppointment')}
                >
                  Book appointment
                </button>
                <button className={`border rounded-md py-2 px-2 ${activeButton === 'makePrepayment' ? 'border-black text-black' : 'border-Gray text-Gray'}`}
                  onClick={() => setActiveButton('makePrepayment')}
                >
                  Make prepayment
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className='bg-[#F1F1F1] py-2 ps-4'>Balance</div>
            <div className='flex justify-between px-4 py-4 w-full'>
              <div className='w-full '>
                <p className='text-sm text-secondaryLight1 mb-1'>Credits Available</p>
                <p className='text-lg'>CA$0.00</p>
              </div>
              <div className='w-full'>
                <p className='text-sm text-secondaryLight1 mb-1'>Outstanding Balance</p>
                <p className='text-lg'>CA$0.00</p>
              </div>
            </div>
          </div>
          <div>
            <div className='bg-[#F1F1F1] py-2 ps-4'>Contact Info</div>
            <div className='px-4 py-4'>
              {contactInfo?.map((item, index) => (
                <div key={index} className='border-b flex items-center gap-3 py-2 px-'>
                  <div className='h-5 border-r-2 pe-2'>
                    <img src={item?.icon} alt='icon' />
                  </div>
                  <div>
                    <p className='text-Gray'>{item?.label}</p>
                    <p className='text-sm mt-1'>{item?.value}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className='bg-[#F1F1F1] py-2 ps-4'>Social Links</div>
            <div className='flex gap-3 py-4 px-4'>
              {socialLinks?.map((item, index) => (
                <a key={index} href={item.url || '#'} target="_blank" rel="noopener noreferrer" className={`${item.bgColor} h-8 w-8 rounded-full flex justify-center items-center`}>
                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                </a>
              ))}
            </div>
            <div className='px-4 py-4'>
              <div className='bg-[#F1F1F1] rounded-xl text-center content-center px-2 py-4'>
                <h4 className='font-bold text-lg'>Download mobile app</h4>
                <p className='text-sm mt-2'>Download mobile app to upload and sign documents, send messages, pay bills and more-all in one place</p>
                <div className='space-x-3 pt-4'>
                  <a href={dashboardData?.playstore_url || '#'} target="_blank" rel="noopener noreferrer" className='h-10 inline-block' >
                    <img src="/assets/icons/playstore-btn.svg" alt="Play Store" className='object-contain h-full' />
                  </a>
                  <a href={dashboardData?.applestore_url || '#'} target="_blank" rel="noopener noreferrer" className='h-10 inline-block' >
                    <img src="/assets/icons/appstore-btn.svg" alt="App Store" className='object-contain h-full' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard