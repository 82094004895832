import React, { useEffect, useState } from 'react';
import { Switch, Form, Input, Button, Steps, Spin, Skeleton } from 'antd';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../../components/global/modals/ConfirmationModal.jsx';
import LoadableButton from '../../../components/button/LoadableButton.jsx';
import ModalComponent from '../../../components/global/modals/ModalComponent.jsx';
import { DisableTFAuthenticationApi, GetAuthDetailsApi, VerifyPasswordApi, VerifyTFAuthenticationApi } from '../../../api/request/settings/settings.js';

export default function TFAuthentication({ userData, isLoading, settingsData, setSettingsData }) {

    const [passwordForm] = Form.useForm();
    const [authForm] = Form.useForm();
    const [isAuthenticationModalOpen, setIsAuthenticationModalOpen] = useState(false);
    const [passwordFormValue, setPasswordFormValue] = useState({ password: '' });
    const [isPasswordVerificationLoading, setIsPasswordVerificationLoading] = useState(false);
    const [isPasswordVerified, setIsPasswordVerified] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [authFormValue, setAuthFormValue] = useState({ one_time_password: '' });
    const [isAuthVerificationLoading, setIsAuthVerificationLoading] = useState(false);
    const [isAuthDetailsLoading, setIsAuthDetailsLoading] = useState(false);
    const [authDetails, setAuthDetails] = useState(null);
    const [isDisableTFAuthConfirmationModal, setIsDisableTFAuthConfirmationModal] = useState(false);
    const [isDisableTFAuthLoading, setIsDisableTFAuthLoading] = useState(false);

    const verifyPassword = async (values) => {
        try {
            setIsPasswordVerificationLoading(true);
            const { data } = await VerifyPasswordApi(values);
            if (data?.status) {
                toast.success(data?.message);
                setIsPasswordVerified(true);
            }
            setIsPasswordVerificationLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsPasswordVerified(false);
            setIsPasswordVerificationLoading(false);
        }
    }

    useEffect(() => {
        setIsPasswordVerified(false);
        passwordForm.resetFields();
        authForm.resetFields();
        setCurrentStep(0);
        setAuthDetails(null);
    }, [isAuthenticationModalOpen]);

    const renderPasswordModal = () => {
        return (
            <div>
                <p className='text-[16px]'>In order to change your login details you must provide your current password.</p>
                <Form autoComplete='off' form={passwordForm} initialValues={passwordFormValue} onFinish={verifyPassword}>
                    <Form.Item name="password" className='mb-0 mt-5' rules={[{ required: true, message: 'Please enter your password!', },]}>
                        <Input.Password placeholder='Password' size='large' />
                    </Form.Item>
                    <div className='flex justify-center items-center gap-5 mt-7'>
                        <button className='px-4 py-2 rounded-md font-semibold text-secondary border border-secondary' onClick={() => setIsAuthenticationModalOpen(false)}>Cancel</button>
                        <LoadableButton
                            className='bg-secondary text-white text-base font-semibold px-5 py-2 rounded-lg'
                            type='submit'
                            lable='Submit'
                            loadingLable='Submitting...'
                            isLoading={isPasswordVerificationLoading}
                        />
                    </div>
                </Form>
            </div>
        )
    }

    const getAuthDetails = async () => {
        try {
            setIsAuthDetailsLoading(true);
            const { data } = await GetAuthDetailsApi();
            if (data?.status) {
                setAuthDetails(data?.data);
            }
            setIsAuthDetailsLoading(false);
        } catch (error) {
            setIsAuthDetailsLoading(false);
            setAuthDetails(null);
        }
    }

    useEffect(() => {
        if (currentStep === 1 && authDetails === null) {
            getAuthDetails();
        }
    }, [currentStep])

    const nextStep = () => { setCurrentStep(currentStep + 1); };

    const prevStep = () => { setCurrentStep(currentStep - 1); };

    const renderFirstStep = () => {
        return (
            <div>
                <p className='text-lg font-semibold'>Download app</p>
                <p className='mt-2 text-[16px]'>Download and configure an authentication app. This will allow you to generate security codes to sign in your account.</p>
                <div className='grid sm:grid-cols-2 gap-3 mt-8'>
                    <div className='text-[16px]'>
                        <p className='font-semibold mb-2'>Android</p>
                        <p className='text-primary'>Authy</p>
                        <p className='text-primary'>Microsoft Authentication</p>
                        <p className='text-primary'>Google Authentication</p>
                    </div>
                    <div className='text-[16px]'>
                        <p className='font-semibold mb-2'>iOS</p>
                        <p className='text-primary'>Authy</p>
                        <p className='text-primary'>Microsoft Authentication</p>
                        <p className='text-primary'>Google Authentication</p>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-5 mt-10'>
                    <button className='px-4 py-2 border border-secondary rounded-md font-semibold text-secondary' onClick={() => setIsAuthenticationModalOpen(false)}>Cancel</button>
                    <button className='px-4 py-2 bg-secondary rounded-md font-semibold text-white' onClick={nextStep}>Next Step</button>
                </div>
            </div>
        )
    }

    const renderSecondStep = () => {
        return (
            <div>
                <p className='text-lg font-semibold'>Scan QR code</p>
                <p className='mt-2 text-[16px]'>Open your Authenticator App. Use the authentication app to scan the QR code below or enter the secret key manually.</p>
                <div className='mt-4'>
                    {isAuthDetailsLoading ? (
                        <div className='flex flex-col items-center gap-2 my-28'>
                            <Spin />
                            <p className='text-secondaryLight1'>Loading...</p>
                        </div>
                    ) : (
                        <div>
                            <img className="size-40 mx-auto" src={authDetails?.qr_code_url} alt='QR Code' />
                            <p className='text-center mt-4'>Secret Key:</p>
                            <p className='text-center font-semibold mt-1'>24K2NA75TAISUCV3KATTUQ6JXJHU5VR5</p>
                            {/* <p className='text-center font-semibold mt-1'>{userData?.google2fa_secret}</p> */}
                        </div>
                    )}
                </div>
                <div className='flex justify-center items-center gap-5 mt-10'>
                    <button className='px-4 py-2 border border-secondary rounded-md font-semibold text-secondary' onClick={prevStep}>Back</button>
                    <button className='px-4 py-2 bg-secondary rounded-md font-semibold text-white' onClick={nextStep}>Next Step</button>
                </div>
            </div>
        )
    }

    const renderThirdStep = () => {
        return (
            <div>
                <p className='text-lg font-semibold'>Enter code from app</p>
                <p className='mt-2 text-[16px]'>After the application is configured, enter the 6-digit code in the below and click “Verify”.</p>
                <Form autoComplete='off' className='w-full' form={authForm} initialValues={authFormValue} onFinish={handleAuthentication} >
                    <div className='mt-8'>
                        <Form.Item className='mb-1' name="one_time_password" rules={[{ required: true, message: '' }]} >
                            <Input.OTP size='large' style={{ width: '100%' }} />
                        </Form.Item>
                    </div>
                    <div className='flex justify-center items-center gap-5 mt-10'>
                        <button className='px-4 py-2 border border-secondary rounded-md font-semibold text-secondary' onClick={prevStep}>Back</button>
                        <LoadableButton
                            className='bg-secondary text-white text-base font-semibold px-5 py-2 rounded-lg'
                            type='submit'
                            lable='Verify'
                            loadingLable='Verifiying...'
                            isLoading={isAuthVerificationLoading}
                        />
                    </div>
                </Form>
            </div>
        )
    }

    const renderAuthenticationFlow = () => {
        let stepItems = [
            { key: '1', title: 'Download app', content: renderFirstStep() },
            { key: '2', title: 'Scan QR code', content: renderSecondStep() },
            { key: '3', title: 'Enter code for app', content: renderThirdStep() }
        ];
        return (
            <div>
                <Steps current={currentStep} items={stepItems} className='border-b-2 pb-6' />
                <div className='mt-6'>
                    {stepItems[currentStep]?.content}
                </div>
            </div>
        )
    }

    const handleAuthentication = async (values) => {
        try {
            setIsAuthVerificationLoading(true);
            const { data } = await VerifyTFAuthenticationApi(values);
            if (data?.status) {
                settingsData.is_2fa_enabled = true;
                setSettingsData(settingsData);
                toast.success(data?.message);
                setIsAuthenticationModalOpen(false);
            }
            setIsAuthVerificationLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsAuthVerificationLoading(false);
        }
    }

    const disableTFAuthenticationHandler = async () => {
        try {
            setIsDisableTFAuthLoading(true);
            const { data } = await DisableTFAuthenticationApi();
            if (data?.status) {
                settingsData.is_2fa_enabled = false;
                setSettingsData(settingsData);
                toast.success(data?.message);
                setIsDisableTFAuthConfirmationModal(false);
            }
            setIsDisableTFAuthLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsDisableTFAuthLoading(false);
        }
    }


    return (
        <div>
            <div className='border rounded-lg p-4'>
                <p className='font-semibold text-lg border-b pb-3'>Two-factor Authentication</p>
                <div className='flex items-center gap-4 mt-4'>
                    {isLoading ?
                        (<Skeleton.Button active={true} size='small' block={true} />)
                        :
                        (<>
                            <Switch
                                checked={settingsData?.is_2fa_enabled}
                                onClick={() => { settingsData?.is_2fa_enabled ? setIsDisableTFAuthConfirmationModal(true) : setIsAuthenticationModalOpen(true) }}
                            // value={settingsData?.is_2fa_enabled}
                            // checkedChildren='On' unCheckedChildren='Off'
                            // onClick={() => setIsDisableTFAuthConfirmationModal(true)}
                            />
                            <p>{settingsData?.is_2fa_enabled ? 'Disable' : 'Enable'} Two Factor Authentication</p>
                        </>)
                    }
                </div>
            </div>

            {/* -------- Enable Two Factor Authentication modal -------- */}
            <ModalComponent isOpen={isAuthenticationModalOpen} setIsOpen={setIsAuthenticationModalOpen} title={`${isPasswordVerified ? '' : 'Authentication'}`} width={600}
                closable={false}>
                {isPasswordVerified ? renderAuthenticationFlow() : renderPasswordModal()}
            </ModalComponent>

            {/* -------- Disable Two Factor Authentication modal -------- */}
            <ConfirmationModal
                isOpen={isDisableTFAuthConfirmationModal}
                setIsOpen={setIsDisableTFAuthConfirmationModal}
                message='Are you sure you want to disable Two Factor Authentication?'
                onConfirm={disableTFAuthenticationHandler}
                isLoading={isDisableTFAuthLoading}
                loadingLabel='Disabling 2FA...'
            />
        </div>
    )
}
