import React, { useState } from 'react';

export default function MyCheckBox({ data }) {

    let { question, options } = data;
    const [selectedValues, setSelectedValues] = useState([]);

    const updateArray = (array = [], newValue) => {
        const index = array?.findIndex(value => value === newValue);
        if (index !== -1) {
            return array.filter(value => value !== newValue);
        } else {
            return [...array, newValue];
        }
    }

    const checkStringInArray = (value) => {
        return selectedValues?.includes(value);
    }

    const onChange = (value) => {
        setSelectedValues(prevSelectedValues => {
            console.log(updateArray(prevSelectedValues, value));
            return updateArray(prevSelectedValues, value);
        });
    }

    return (
        <div className='mt-6'>
            <p className='mb-2'>{question}</p>
            <div className='w-full flex flex-wrap gap-5'>
                {
                    options?.map((item, index) => (
                        <div
                            key={index}
                            className={`py-3 px-12 font-medium border rounded-md cursor-pointer duration-500 ${checkStringInArray(item) ? 'text-primary border-primary bg-primaryLight' : 'text-Gray'}`}
                            onClick={() => onChange(item)}
                        >
                            {item}
                        </div>
                    ))
                }
            </div>
        </div >
    )
}