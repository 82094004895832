import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login';

const FacebookLoginComponent = () => {
    const [user, setUser] = useState(null);

    const responseFacebook = (response) => {
        console.log('Facebook Response:', response);
        if (response.status !== 'unknown') {
            setUser({
                name: response.name,
                email: response.email,
                profilePicture: response.picture?.data?.url,
                provider: 'Facebook',
            });
        }
        else {
            console.error('Facebook Login Failed');
        }
    };

    const componentClicked = () => {
        console.log('Facebook login button clicked');
    };
    return (
        <div>
            <FacebookLogin
                appId="1088597931155576"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                size="small"
                cssClass="custom-facebook-button"
                icon= <img src='assets/icons/facebook.svg' alt='icon' className='me-1' />
                textButton="Sign in with Facebook"
                onClick={componentClicked}
            />
        </div>
    )
}

export default FacebookLoginComponent