import React from 'react';
import MyInput from './formElements/Input.jsx';
import MyDatePicker from './formElements/DatePicker.jsx';
import MyRadio from './formElements/Radio.jsx';
import MyCheckBox from './formElements/CheckBox.jsx';

export default function QuestionContainer({ questions }) {

  const QuestionRender = (question) => {
    switch (question?.questionType) {
      case 'input':
        return <MyInput data={question} />
      case 'date':
        return <MyDatePicker data={question} />
      case 'radio':
        return <MyRadio data={question} />
      case 'checkbox':
        return <MyCheckBox data={question} />
      default:
    }
  }

  return (
    <div>
      {
        questions?.map((question, index) => (
          QuestionRender(question)
        ))
      }
    </div>
  )
}
