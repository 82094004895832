import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import LoadableButton from '../../components/button/LoadableButton';
import toast from 'react-hot-toast';
import { GetCountryDetailsApi } from '../../api/request/countryDetail';


const { Option } = Select;

const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [countryDetails, setCountryDetails] = useState([]);

    const getCountryDetails = async () => {
        try {
            const { data } = await GetCountryDetailsApi();
            if (data?.status) {
                setCountryDetails(data?.data);
            }
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
        }
    }

    useEffect(() => {
        getCountryDetails();
    }, [])

    return (
        <>
            <div className='lg:w-3/5 md:w-[80%] w-full sm:p-0 p-4'>
                <Form layout='vertical' autoComplete='off' className='space-y-7'>
                    <p className="sm:text-3xl text-2xl text-center font-bold mb-6">Welcome to <span className='text-primary'>Trackmydoc</span></p>
                    <Form.Item
                        label={<p className='font-semibold'>Email Address</p>}
                        name="email"
                        style={{ marginTop: '0px' }}
                        rules={[
                            { required: true, message: 'Please input your email!', },
                            { type: 'email', message: 'Please input valid email!', },
                        ]}
                    >
                        <Input placeholder='Email address' size='large' />
                    </Form.Item>
                    <div className="flex sm:flex-row flex-col sm:gap-4">
                        <Form.Item name="country_code" className='sm:min-w-[28%]' rules={[{ required: true, message: 'Please select country', },]}>
                            <Select placeholder='Country code' size="large" >
                                {countryDetails.map((countryData, index) => (
                                    <Option key={index} value={countryData?.phonecode}>
                                        <div className="flex items-center space-x-3">
                                            <div className='rounded-full bg-secondaryLight w-9 h-9 flex justify-center items-center'>
                                                <img src={countryData?.image} alt="flag" className="w-7 h-7 object-contain rounded-full" />
                                            </div>
                                            <span className='text-base'>+ {countryData?.phonecode}</span>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="mobile_no" rules={[
                            { required: true, message: 'Please input mobile number!', },
                            // {
                            //     pattern: /^\d{10}$/,
                            //     message: 'Mobile number should be 10 digits!',
                            // },
                        ]} className='w-full'>
                            <Input size="large" placeholder="Mobile No." />
                        </Form.Item>
                    </div>

                    <Form.Item name="tnc" valuePropName="checked" className='mb-0 mt-3'
                        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions.!')), }]}>
                        <Checkbox>
                            <div className='cursor-pointer'>
                                I agree all the
                                <span className='text-primary '> Terms </span>
                                and
                                <span className='text-primary '> Privacy Policies</span>
                            </div>
                        </Checkbox>
                    </Form.Item>
                    <LoadableButton
                        type='submit'
                        isLoading={isLoading}
                        lable='Countinue'
                        loadingLable='Creating...'
                        img='assets/icons/ArrowRight.svg'
                        className='bg-secondary text-white text-base w-full py-2 rounded-md mb-5 flex justify-center items-center'
                    />
                </Form>
            </div>
        </>
    )
}

export default SignUp