import React from 'react';
import { Input } from 'antd';

export default function MyInput({ data }) {

    let { question, type, placeholder } = data;

    const onChange = (event) => {
        console.log(event?.target?.value);
    }

    return (
        <div className='mt-6'>
            <p className='mb-2'>{question}</p>
            <Input
                className='py-3 ps-4'
                size='large'
                type={type || 'text'}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}