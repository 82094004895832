

import Forgot from '../pages/auth/Forgot.jsx';
import Login from '../pages/auth/Login.jsx';
import Otp from '../pages/auth/Otp.jsx';
import Register from '../pages/auth/Register.jsx';
import ResetPassword from '../pages/auth/ResetPassword.jsx';
import SignUp from '../pages/auth/SignUp.jsx';
import TFAuthenticator from '../pages/auth/TFAuthenticator.jsx';
import Chat from '../pages/chat/Chat.jsx';
import Dashboard from '../pages/dashboard/Dashboard.jsx';
import Trash from '../pages/documents/components/Trash.jsx';

import Documents from '../pages/documents/Documents.jsx';

import CreateFiles from '../pages/files/components/CreateFiles.jsx';
import QuestionAnswerFlow from '../pages/files/components/QuestionAnswerFlow.jsx';
import Files from '../pages/files/Files.jsx';

import Settings from '../pages/settings/Settings.jsx';

export const initialRoutes = () => {
    const routes = [
        // ============ Auth ===============
        { path: "/login", type: "auth", component: Login, title: 'Login' },
        { path: "/signup", type: "auth", component: SignUp, title: 'SignUp' },
        { path: "/register", type: "auth", component: Register, title: 'Register' },
        { path: "/otp", type: "auth", component: Otp, title: 'Otp' },
        { path: "/authentication", type: "auth", component: TFAuthenticator, title: 'Authentication' },
        { path: "/forget_password", type: "auth", component: Forgot, title: 'ForgotPassword' },
        { path: "/change_password/:token", type: "auth", component: ResetPassword, title: 'ResetPassword' },
        // ============ Public =============

        // ============ Privet ==============
        { path: "/dashboard", type: "private", component: Dashboard, title: "Dashboard" },
        { path: "/", type: "private", component: Dashboard, title: "Dashboard" },
        { path: "/document", type: "private", component: Documents, title: "Document" },
        { path: "/documents_trash", type: "private", component: Trash, title: "Trash" },
        { path: "/files", type: "private", component: Files, title: "Files" },
        { path: "/create_files", type: "private", component: CreateFiles, title: "Files" },
        { path: "/question_answer_flow", type: "private", component: QuestionAnswerFlow, title: "Question Answer Flow" },
        { path: "/chat", type: "private", component: Chat, title: "Chat" },
        { path: "/settings", type: "private", component: Settings, title: "settings" },
        { path: "/chat", type: "private", component: Chat, title: "Chat" },
        { path: "/settings", type: "private", component: Settings, title: "settings" },
    ]
    return routes;
}