import React, { useState } from 'react';
import { Form, Input } from 'antd';
import LoadableButton from '../../components/button/LoadableButton.jsx'
import { VerifyTFAuthenticationApi } from '../../api/request/auth';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUserDetails, setloggedIn } from "../../store/Slices/userSlice.js";
import { setCookies } from "../../services/Cookies.jsx";

export default function TFAuthenticator() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({ one_time_password: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            let params = {
                email: localStorage.getItem('email'),
                ...values
            }
            const { data } = await VerifyTFAuthenticationApi(params);
            if (data?.status) {
                dispatch(setloggedIn(true));
                dispatch(setUserDetails(data?.data));
                setCookies('token', data?.token);
                toast.success(data?.message);
                form.resetFields();
                localStorage.removeItem('email');
                navigate('/dashboard');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className='lg:w-3/5 md:w-[80%] w-full sm:p-0 p-4'>
            <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={handleSubmit} className='space-y-8'>
                <div className='text-center'>
                    <p className='font-bold sm:text-3xl text-2xl mb-3'>2F Authentication</p>
                    <p className="text-sm text-Gray">Enter the verification code that is showing in your Authenticator app.</p>
                </div>
                <Form.Item name="one_time_password" rules={[{ required: true, message: '' }]} >
                    <Input.OTP size='large' style={{ width: '100%' }} />
                </Form.Item>
                <LoadableButton
                    type='submit'
                    lable='Verify'
                    loadingLable='Verifying...'
                    isLoading={isLoading}
                    img='assets/icons/ArrowRight.svg'
                    className='bg-secondary text-white text-base w-full py-2 rounded-md mb-5 flex justify-center items-center'
                />
                <div className='text-primary flex items-center justify-center mb-5' ><img src="assets/icons/ArrowLeft.svg" alt="icon" className='me-3' />
                    <Link to='/login'>Back to login</Link>
                </div>
            </Form>
        </div>
    )
}